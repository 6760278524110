import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

class GuestService {
  http: AxiosInstance;
  constructor() {
    this.http = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  onResponse(resp: AxiosResponse): any {
    return resp.data;
  }

  onError(e: AxiosError): any {
    const { data } = <any>e.response;
    console.log(">>> http error data: ", data);
    return Promise.reject(data);
  }

  createGuest(): any {
    return this.http
      .post<any>("/guest")
      .then(this.onResponse)
      .catch(this.onError);
  }
}

export default new GuestService()