import * as React from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LaunchIcon from "@mui/icons-material/Launch";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { closeDialog, createGuest } from "./guestSlice";

export default function Guest() {
  const [copied, setCopied] = React.useState(false);
  const open = useAppSelector((state: RootState) => state.guest.open);
  const moderatorUrl = useAppSelector(
    (state: RootState) => state.guest.moderatorUrl
  );
  const error = useAppSelector((state: RootState) => state.guest.error);
  const dispatch = useAppDispatch();
  const createNewGuest = () => {
    dispatch(createGuest());
  };
  const handleClose = () => {
    dispatch(closeDialog());
  };
  const copyLink = () => {
    setCopied(true);
    navigator.clipboard.writeText(moderatorUrl);
  };
  const openLink = () => {
    const win = window.open(moderatorUrl, "_blank");
    win?.focus();
  };

  return (
    <>
      <Box component="section" sx={{ p: 2, border: "1px dashed grey" }}>
        <Button variant="outlined" onClick={createNewGuest}>
          Create new wordclouds session
        </Button>
        <Typography variant="overline" />
        {!!error && (
          <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
            {error.message}
          </Alert>
        )}
      </Box>
      <Dialog
        style={{ minWidth: "800px" }}
        fullScreen={false}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-title">{"Share"}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            <FormControl fullWidth>
              <InputLabel
                variant="outlined"
                sx={{ backgroundColor: "white" }}
                htmlFor="_moderation"
              >
                Wordclouds moderation URL
              </InputLabel>
              <OutlinedInput
                id="moderation"
                type="text"
                value={moderatorUrl}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={copyLink}>
                      <ContentCopyIcon />
                    </IconButton>
                    <Snackbar
                      message="Copied to clipboard"
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      autoHideDuration={2000}
                      onClose={() => setCopied(false)}
                      open={copied}
                    />
                    <IconButton onClick={openLink}>
                      <LaunchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  "aria-label": "MModeration",
                }}
              />
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
